import React from "react";
import Committee from "./committee";
import Article from "./article";

import "tachyons";

const CommitteeArticleSection = () => {
  return (
    <section>
      <div className="ph3 ph5-ns pt5">
        <div className="mw9 center overflow-auto">
          <article className="dt-ns dt--fixed-ns">
            <Committee />
            <Article />
          </article>
        </div>
      </div>
    </section>
  );
};

export default CommitteeArticleSection;
