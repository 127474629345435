import React from "react";
import PortableText from "../portableText";
import { mapEdgesToNodes } from "../../lib/helpers";
import { ArticleListQuery } from "../../hooks/articleListQuery";

import "tachyons";

const Article = () => {
  const getArticleList = () => {
    const data = ArticleListQuery();
    const nodes = data || {} ? mapEdgesToNodes(data.articles) : [];
    return nodes;
  };

  const articleList = getArticleList();
  const size = articleList.length;

  const renderUnderLine = (position, linkUrl) => {
    if (size === position + 1) {
      return (
        <div className="lh-copy serif pb4 bb">
          <a href={linkUrl}>Baca Selengkapnya</a>
        </div>
      );
    } else {
      return (
        <div className="lh-copy serif mb4">
          <a href={linkUrl}>Baca Selengkapnya</a>
        </div>
      );
    }
  };

  return (
    <div className="dtc-ns pl4-m pl4-l pl0-ns overflow-auto">
      <h3 className="f4 ttu fw4 mb4 pb2">Artikel Terkini</h3>
      {articleList.map((article, position) => (
        <div>
          <div className="db f6 silver ttu tracked sans-serif bt pt3 mb3">
            {article.publishedAt}
          </div>
          <div className="display dark-gray f3">{article.title}</div>
          <div className="lh-copy serif">
            <PortableText blocks={article._rawExcerpt} />
          </div>
          {renderUnderLine(position, article.linkUrl)}
        </div>
      ))}
    </div>
  );
};

export default Article;
