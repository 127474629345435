import React from "react";
import PortableText from "../portableText";
import { mapEdgesToNodes } from "../../lib/helpers";
import { AnnouncementListQuery } from "../../hooks/announcementListQuery";

import "../../styles/custom.tachyons.css";
import "tachyons";

const Announcement = () => {
  const getAnnouncementList = () => {
    const data = AnnouncementListQuery();
    const nodes = data || {} ? mapEdgesToNodes(data.announcements) : [];
    return nodes;
  };

  const announcementList = getAnnouncementList();

  return (
    <section className="cf ph3 ph5-ns bg-black-60 white-80 mt4">
      <div className="mw9 center">
        <div className="flex flex-column justify-center items-center pa2 pv5">
          {announcementList.map((announcement) => (
            <div>
              <h1 className="fw1 display db white-80 f2 tc">
                {announcement.title}
              </h1>
              <p className="f5 serif mw7 tc lh-copy">
                <PortableText blocks={announcement._rawPreview} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Announcement;
