import { useStaticQuery, graphql } from "gatsby";

export const AnnouncementListQuery = () => {
  const announcementListData = useStaticQuery(graphql`
    query AnnouncementListQuery {
      announcements: allSanityAnnouncement(
        limit: 1
        sort: { fields: publishedAt, order: DESC }
        filter: {
          slug: { current: { ne: "null" } }
          publishedAt: { ne: "null" }
        }
      ) {
        edges {
          node {
            id
            publishedAt(formatString: "dddd, DD MMM YYYY", locale: "id")
            title
            _rawPreview
          }
        }
      }
    }
  `);

  return announcementListData;
};
