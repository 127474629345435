import { useStaticQuery, graphql } from "gatsby";

export const ArticleListQuery = () => {
  const articleListData = useStaticQuery(graphql`
    query ArticleListQuery {
      articles: allSanityArticle(
        limit: 3
        sort: { fields: publishedAt, order: DESC }
      ) {
        edges {
          node {
            id
            publishedAt(formatString: "dddd, DD MMM YYYY", locale: "id")
            title
            _rawExcerpt
            linkUrl
          }
        }
      }
    }
  `);

  return articleListData;
};
