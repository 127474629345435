import React from "react";
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import PortableText from "../portableText";

import "../../styles/custom.tachyons.css";
import "tachyons";

export default function News(props) {
  return (
    <React.Fragment>
      <div className="dtc-ns pr4-m pr4-l pr0-ns overflow-auto">
        <h3 className="f4 ttu fw4 mb4 pb2">Berita Terkini</h3>
        <div className="">
          {props.node.mainImage && props.node.mainImage.asset && (
            <img
              className=""
              src={imageUrlFor(buildImageObj(props.node.mainImage))
                .width(760)
                .height(Math.floor((9 / 16) * 760))
                .auto("format")
                .url()}
              alt={props.node.mainImage.alt}
            />
          )}
          <div className="pv3">
            <span className="fw1 display dark-gray db w-100 mw7 f3 f2-ns">
              {props.node.title}
            </span>
            <p className="db f6 silver ttu tracked sans-serif">01 Januari 2021</p>
            {props.node._rawPreview && (
              <p className="serif w-100 mw7 tj f5 lh-copy mt4 mb4 db">
                <PortableText blocks={props.node._rawPreview} />
              </p>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
