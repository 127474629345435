import { useStaticQuery, graphql } from "gatsby";

export const CommitteeListQuery = () => {
  const committeeListData = useStaticQuery(graphql`
    fragment SanityImage on SanityMainImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
    }

    query CommitteeListQuery {
      committees: allSanityCommittee(
        filter: { position: { positionId: { in: [1, 2] } } }
        sort: { fields: position___positionId, order: ASC }
      ) {
        edges {
          node {
            name
            position {
              _id
              positionName
              positionId
            }
            mainImage {
              ...SanityImage
              alt
            }
            startPeriods(formatString: "YYYY", locale: "id")
            endPeriods(formatString: "YYYY", locale: "id")
          }
        }
      }
    }
  `);

  return committeeListData;
};
