import { useStaticQuery, graphql } from "gatsby";

export const AgendaListQuery = () => {
  const agendaListData = useStaticQuery(graphql`
    query AgendaListQuery {
      agendas: allSanityAgenda(limit: 3, sort: { fields: time, order: DESC }) {
        edges {
          node {
            id
            time(formatString: "dddd, DD MMM YYYY hh:mm", locale: "id")
            title
            _rawEvent
            location
            attendee
          }
        }
      }
    }
  `);

  return agendaListData;
};
