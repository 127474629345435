import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import Layout from "../components/common/layout";
import News from "../components/homepage/news";
import Agenda from "../components/homepage/agenda";
import Announcement from "../components/homepage/announcement";
import CommitteeArticleSection from "../components/homepage/committeeArticleSection";

import "../styles/custom.tachyons.css";
import "tachyons";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  query IndexPageQuery {
    news: allSanityNews(
      limit: 5
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { ne: "null" } }, publishedAt: { ne: "null" } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
            caption
          }
          title
          slug {
            current
          }
          _rawPreview
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  //   if (errors) {
  //     return (
  //       <Layout>
  //         <GraphQLErrorList errors={errors} />
  //       </Layout>
  //     )
  //   }

  const newsNodes = (data || {}).news
    ? mapEdgesToNodes(data.news)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      <section>
        <div className="ph3 ph5-ns pt5">
          <div className="mw9 center overflow-auto">
            <article className="dt-ns dt--fixed-ns">
              <News node={newsNodes[0]} />
              <Agenda />
            </article>
          </div>
        </div>
      </section>
      <Announcement />
      <CommitteeArticleSection />
    </Layout>
  );
};

export default IndexPage;
