import React from "react";
import { buildImageObj, mapEdgesToNodes } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import { CommitteeListQuery } from "../../hooks/committeeListQuery";

import "tachyons";

const Committee = () => {
  const getCommitteeList = () => {
    const data = CommitteeListQuery();
    const nodes = data || {} ? mapEdgesToNodes(data.committees) : [];
    return nodes;
  };

  const committeeList = getCommitteeList();

  return (
    <div className="dtc-ns pr4-m pr4-l pr0-ns overflow-auto">
      <h3 className="f4 ttu fw4 mb4 pb2">Pimpinan Desa</h3>
      {committeeList.map((committee) => (
        <div className="fl w-50 pr4 pr4-ns">
          <img
            src={imageUrlFor(buildImageObj(committee.mainImage))
              .width(260)
              .height(Math.floor((9 / 16) * 520))
              .auto("format")
              .url()}
            alt={committee.mainImage.alt}
          />
          <h3>{committee.name}</h3>
          <h4>{committee.position.positionName}</h4>
          <p>
            Periode {committee.startPeriods} - {committee.endPeriods}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Committee;
