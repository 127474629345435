import React from "react";
import PortableText from "../portableText";
import { mapEdgesToNodes } from "../../lib/helpers";
import { AgendaListQuery } from "../../hooks/agendaListQuery";

import "tachyons";

const Agenda = () => {
  const getAgendaList = () => {
    const data = AgendaListQuery();
    const agendaNodes = data || {} ? mapEdgesToNodes(data.agendas) : [];
    return agendaNodes;
  };

  const agendaList = getAgendaList();
  const size = agendaList.length;

  const renderUnderLine = (position, attendee) => {
    if (size === position + 1) {
      return (
        <div className="f5 lh-copy serif pb4 bb">Dimohon hadir: {attendee}</div>
      );
    } else {
      return (
        <div className="f5 lh-copy serif mb4">Dimohon hadir: {attendee}</div>
      );
    }
  };

  return (
    <div className="dtc-ns pl4-m pl4-l pl0-ns overflow-auto">
      <h3 className="f4 ttu fw4 mb4 pb2">Agenda Desa Terkini</h3>
      {agendaList.map((agenda, position) => (
        <div>
          <div className="db f6 silver ttu tracked sans-serif bt pt3 mb3">
            {agenda.time}
          </div>
          <div className="display dark-gray f3">{agenda.title}</div>
          <div className="f5 lh-copy serif">
            <PortableText blocks={agenda._rawEvent} />
          </div>
          {renderUnderLine(position, agenda.attendee)}
        </div>
      ))}
    </div>
  );
};

export default Agenda;
